import React, { Suspense } from 'react';
import './App.css';

import { ClerkProvider, RedirectToSignIn, SignedIn, SignedOut } from '@clerk/clerk-react';
import { createTheme, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { OwnerCheck } from './component/OwnerCheck';
import { DialogProvider } from './context/DialogContext';
import { RootContextProvider } from './context/RootContextProvider';
import { ShopContextProvider } from './context/ShopContextProvider';
import { BasicLayout } from './layout/BasicLayout';
import { ShopLayout } from './layout/ShopLayout';
import { BacklistSearchScreen } from './screen/BlacklistSearchScreen';
import { ComingSoonFeatureScreen } from './screen/ComingSoonFeatureScreen';
import { CustomerDetailScreen } from './screen/CustomerDetailScreen';
import { HomeScreen } from './screen/HomeScreen';
import { LoanDetailScreen } from './screen/LoanDetailScreen';
import { LoanListScreen } from './screen/LoanListScreen';
import { PaymentDetailScreen } from './screen/PaymentDetailScreen';
import { PaymentListScreen } from './screen/PaymentListScreen';
import { ShopDashboardScreen } from './screen/ShopDashboardScreen';
import { ShopDetailScreen } from './screen/ShopDetailScreen';
import { ShopLandingScreen } from './screen/ShopLandingScreen';
import { ShopRegisterScreen } from './screen/ShopRegisterScreen';
import { UserDetailScreen } from './screen/UserDetailScreen';
import { UserListScreen } from './screen/UserListScreen';
import { LoanCalculatorScreen } from './screen/LoanCalculatorScreen';

const theme = createTheme({
  palette: {
    primary: {
      main: '#263238'

    }
  },
  typography: {
    fontFamily: 'Kanit'
  },
});

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <BasicLayout />,
      children: [
        {
          path: "",
          element: <HomeScreen />
        },
        {
          path: "register-shop",
          element: <ShopRegisterScreen />
        },
        {
          path: "register-shop/:shopId",
          element: <ShopRegisterScreen />
        }
      ]
    },
    {
      path: "/:shopId",
      element: (<ShopContextProvider><ShopLayout /></ShopContextProvider>),
      children: [
        {
          index: true,
          element: <ShopLandingScreen />
        },
        {
          path: "dashboard",
          element: <ShopDashboardScreen />
        },
        {
          path: "settings/shop",
          element: <OwnerCheck><ShopDetailScreen /></OwnerCheck>
        },
        {
          path: "loan",
          element: <LoanListScreen />,
        },
        {
          path: "loan-calculator",
          element: <LoanCalculatorScreen />
        },
        {
          path: "loan/create",
          element: <LoanDetailScreen mode='create' />,
        },
        {
          path: "loan/:id",
          element: <LoanDetailScreen mode='update' />,
        },
        {
          path: "loan/:loanId/create-payment",
          element: <PaymentDetailScreen mode='create' />
        },
        {
          path: "payment",
          element: <PaymentListScreen />,
        },
        {
          path: "payment/:id",
          element: <PaymentDetailScreen mode='update' />
        },
        {
          path: "blacklist",
          element: <BacklistSearchScreen />,
        },
        {
          path: "customer/:id",
          element: <CustomerDetailScreen />,
        },
        {
          path: "settings/user",
          element: <OwnerCheck><UserListScreen /></OwnerCheck>,
        },
        {
          path: "user/:id",
          element: <UserDetailScreen />,
        },
        {
          path: "coming-soon",
          element: <ComingSoonFeatureScreen />
        }
      ]
    },
  ]
);

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } } })

function App() {

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<div>Loading...</div>}>
          <ClerkProvider publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY!}>
            <RootContextProvider>
              <DialogProvider>
                <Toaster />
                <SignedIn>
                  <ThemeProvider theme={theme}>
                    <RouterProvider router={router} />
                  </ThemeProvider>
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </DialogProvider>
            </RootContextProvider>
          </ClerkProvider>
        </Suspense>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

export default App;
