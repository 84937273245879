import { createContext, ReactNode, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useShopMemberQuery, useShopQuery, useUserProfileQuery } from "../client/query-client";
import { ShopEvent, ShopMemberUser } from "../client/motal-api/generated";
import { dayDiff } from "../util/DateUtil";

import ConstructionIcon from '@mui/icons-material/ConstructionOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import InsertChartOutlinedOutlinedIcon from '@mui/icons-material/InsertChartOutlinedOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import PriceCheckIcon from '@mui/icons-material/PriceCheckOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';

type ShopContextType = {
    shopId?: string
    shop?: ShopEvent
    selectedMenu?: string,
    setSelectedMenu: (menuId: string) => void,
    remainingDays: number,
    isOwner: boolean,
    menuData?: {
        key: string
        label: string
        icon: ReactNode,
        display: boolean
    }[],
    shopMenuData?: {
        key: string
        label: string
        icon: ReactNode,
        display: boolean
    }[],
}

const ShopContext = createContext<ShopContextType>({
    setSelectedMenu: () => { },
    remainingDays: 0,
    isOwner: false
});

type ShopContextProviderProps = {
    children: ReactNode
}

export const ShopContextProvider = ({ children }: ShopContextProviderProps) => {
    const [selectedMenu, setSelectedMenu] = useState<string>()

    const { shopId } = useParams()
    const shop = useShopQuery(shopId || '').data
    const currentDate = new Date()
    const remainingDays = shop?.expireAt ? dayDiff(new Date(shop.expireAt), currentDate) : 0

    const shopMember = useShopMemberQuery(shopId || '')
    const userProfile = useUserProfileQuery()
    const onlyOwner = shopMember.data?.shopMemberUsers?.find(i => i.primaryEmail === userProfile.data?.primaryEmail)?.shopRole === ShopMemberUser.shopRole.OWNER
    const isOwner = onlyOwner
    const menuData = [
        {
            key: '/:shopId/dashboard',
            label: 'แดชบอร์ด',
            icon: <InsertChartOutlinedOutlinedIcon />,
            display: onlyOwner
        },
        {
            key: '/:shopId/loan',
            label: 'สินเชื่อ',
            icon: <CreditScoreOutlinedIcon />,
            display: true
        },
        {
            key: '/:shopId/loan-calculator',
            label: 'คำนวณสินเชื่อ',
            icon: <CalculateOutlinedIcon />,
            display: true
        },
        {
            key: '/:shopId/blacklist',
            label: 'ตรวจสอบแบล็คลิส',
            icon: <PersonSearchOutlinedIcon />,
            display: true
        },
        {
            key: '/:shopId/coming-soon',
            label: 'จำนำ (กำลังพัฒนา)',
            icon: <PriceCheckIcon />,
            display: true
        },
        {
            key: '/:shopId/coming-soon',
            label: 'เคลมซ่อม (กำลังพัฒนา)',
            icon: <ConstructionIcon />,
            display: true
        },
        {
            key: '/:shopId/coming-soon',
            label: 'POS (กำลังพัฒนา)',
            icon: <StorefrontOutlinedIcon />,
            display: true
        },
    ]

    const shopMenuData = [
        {
            key: '/:shopId/settings/shop',
            label: 'ร้านค้า',
            icon: <StoreOutlinedIcon />,
            display: onlyOwner
        },
        {
            key: '/:shopId/settings/user',
            label: 'ผู้ใช้งาน',
            icon: <PeopleOutlinedIcon />,
            display: onlyOwner
        },
    ]

    return (
        <ShopContext.Provider value={{
            shopId,
            shop,
            selectedMenu,
            setSelectedMenu,
            remainingDays,
            menuData,
            shopMenuData,
            isOwner
        }}>
            {children}
        </ShopContext.Provider>
    )
}

export const useShopContext = () => useContext(ShopContext);